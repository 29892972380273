<template>
  <div class="s3m-settings_list">
    <h1>Profil</h1>
    <v-card>
      <div class="comp_mail">E-Mail Adresse für Login: {{ editUser && editUser.email }}</div>
      <div class="comp-logo">
        <br>
        <v-label>Firmenlogo</v-label>
        <v-file-input
            :size-limit="500"
            v-model="file"
            label="Logo (max 500kb)"
            prepend-icon="mdi-camera"
            accept="image/*"
            @change="previewImage"
        ></v-file-input>
        <img v-if="imageUrl" :src="imageUrl" alt="Logo Vorschau" />
      </div>
      <v-btn color="blue" @click="openForm">Firmendaten anzeigen</v-btn>
    </v-card>
    <confirm-dialog ref="confirmDelete"/>
    <GeneratedForm ref="generatedForm"></GeneratedForm>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog'
import {mapMutations, mapState} from "vuex";
import APIService from "@/services/APIService";
import store from "@/store";
import GeneratedForm from "@/components/GeneratedForm.vue";

export default {
  name: "Settings",
  components: { ConfirmDialog, GeneratedForm },
  data() {
    return {
      teamMembers: [],
      managers: {},
      file: null,
      imageUrl: null,
      editUser: null
    }
  },
  methods: {
    ...mapMutations(['updateManagers']),
    saveSettings () {
      APIService.updateUser({id: this.editUser.id, managers: this.managers})
      store.commit('updateManagers', this.managers)
    },
    getUserItemText(item) {
      return `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''} (${item.email})`;
    },
    removeManager(item) {
      const index = this.managers.indexOf(item.id)
      if (index >= 0) this.managers.splice(index, 1)
    },
    previewImage() {
      console.log(this.file);
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(this.file[0]);
      }
    },
    openForm() {
      console.log(this.editUser);
      this.$refs.generatedForm.show('kunde', this.editUser.kundeObj, this.editUser.kundeObj.id, "R");  // Ersetzen Sie 'type' und 'model' durch Ihre tatsächlichen Argumente
    },
  },
  mounted() {
    //("kunde", item, item.id, this.kundetyp)
    this.editUser = this.user;
    if(this.$store.state.editUser){
      this.editUser = this.$store.state.editUser;
    }
  },
  computed: {
    ...mapState(['user']),
  },
  async beforeRouteEnter(to, from, next) {
    next( async vm => {
      // Wir überprüfen, ob 'editUser' im Zustand gesetzt ist und benutzen diesen, ansonsten benutzen wir 'user'
      const targetUser = vm.$store.state.editUser ?? vm.$store.state.user

      // 'editUser' wird zu 'user' wenn 'editUser' gesetzt ist
      vm.editUser = targetUser

      vm.managers = [...targetUser.managers]
      vm.teamMembers = await APIService.getTeamMembers(targetUser.id)
    })
  }
}
</script>

<style scoped>

</style>
